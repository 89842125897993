import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import classNames from 'classnames'
import siteLogo from '../../images/lodastack-title-logo_128x128.png'
import styles from './Header.module.css'

const Header = ({ siteName }) => {
  const [opened, setOpened] = useState(false)
  const toggleOpened = () => setOpened(!opened)
  const menuCls = classNames({
    [styles.menu]: 1,
    [styles.menuOpened]: opened
  })

  return (
    <header className={styles.header}>
      <div className={styles.wrapper}>
        <h1 className={styles.siteName}>
          <Link
            to="/"
          >
            <img src={siteLogo} alt="Site logo" width="36px" height="36px" />
            <span>{siteName}</span>
          </Link>
        </h1>
        <div className={styles.menuTrigger}>
          <button onClick={toggleOpened}>
            <i className="lsicon ls-icon-nav"></i>
          </button>
        </div>
        <nav className={menuCls}>
          <ul>
            <li>
              <Link to="/docs/introduction.html">产品特性</Link>
            </li>
	        <li>
              <Link to="/docs/solutions.html">解决方案</Link>
            </li>
            <li>
              <Link to="/docs/getting-started.html">文档</Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteName: PropTypes.string,
}

Header.defaultProps = {
  siteName: ``,
}

export default Header
