import React from 'react'
import PropTypes from 'prop-types'

import styles from './Footer.module.css'

const Footer = ({ siteName }) => {
  const siteNavData = [
    {
      title: '产品',
      children: [
        {
          title: '介绍',
          url: '/docs/introduction.html'
        },
        {
          title: '快速上手',
          url: '/docs/getting-started.html'
        },
        {
          title: '价格',
          url: '/pricing'
        }
      ]
    },
    {
      title: '开发者指南',
      children: [
        {
          title: '使用文档',
          url: '/docs/introduction.html'
        },
        {
          title: 'Github 仓库',
          url: 'https://github.com/lodastack'
        },
        {
          title: '问题反馈',
          url: 'https://github.com/lodastack-issues'
        }
      ]
    },
    // {
    //   title: '资源',
    //   children: [
    //     {
    //       title: '用户协议',
    //       url: '/policies/agreement/'
    //     },
    //     {
    //       title: '隐私协议',
    //       url: '/policies/privacy/'
    //     },
    //     {
    //       title: 'Cookie 使用协议',
    //       url: '/policies/cookie/'
    //     },
    //   ]
    // },
    {
      title: '公司',
      children: [
        {
          title: '关于',
          url: '/about.html'
        },
        {
          title: '新闻报道',
          url: '/news.html'
        },
        {
          title: '客户',
          url: '/customers.html'
        },
        {
          title: '合作伙伴',
          url: '/partners.html'
        }
      ]
    }
  ]
  const siteNav = siteNavData.map((list, index) => (
    <div className={styles.column} key={index}>
      <h4>{list.title}</h4>
      <ul>
        {list.children.map((listItem, i) => (
          <li key={i}>
            <a href={listItem.url}>{listItem.title}</a>
          </li>
        ))}
      </ul>
    </div>
  ))

  return (
    <footer className={styles.footer}>
      <div className={styles.wrapper}>
        <div className={styles.siteNav}>
          {siteNav}
        </div>
        <div className={styles.siteInfo}>
          <h3>{siteName}</h3>
          <p className={styles.copyright}>
            © {new Date().getFullYear()} {siteName} 版权所有
          </p>
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  siteName: PropTypes.string
}

Footer.defaultProps = {
  siteName: ``
}

export default Footer
